
import { mapActions, mapGetters } from 'vuex'
import constants from '@/utils/constants'

export default {
  data:() => ({
    typeOptions: constants.typeOptions,
  }),

  computed: {
    ...mapGetters('data', ['trackingReasons']),

    trackingReasonsOptions () {
      if (this.lodash.get(this, 'trackingReasons', []).length === 0) {
        return []
      }

      const trackingReasons = this.lodash.concat(
        this.default.parent,
        this.trackingReasons.map((trackingReason) => ({
          label: trackingReason.title,
          value: trackingReason.id,
        })),
      )

      return trackingReasons
    },
  },

  methods: {
    ...mapActions('data', ['fetchTrackingReasons']),
  },

  beforeMount () {
    if (this.lodash.get(this, 'trackingReasons', []).length === 0) {
      this.fetchTrackingReasons()
    }
  },
}
